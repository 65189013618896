// src/components/PublishItemPopup.js
import ReactDOM from 'react-dom';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import Button from './Button/Button';
import H2 from './H2/H2';
import closeBtnImage from '../assets/btn-close.png';
import InputMask from 'react-input-mask';
import { loadGoogleMapsScript } from '../utils/loadGoogleMapsScript';

const PublishItemPopup = ({ userId, onClose }) => {
  const navigate = useNavigate();
  const [itemData, setItemData] = useState({
    type: '',
    title: '',
    category: '',
    contactEmail: '',
    zipCode: '',
    description: '',
    estado: '',
    cidade: '',
    bairro: '',
    endereco: '',
    numero: ''
  });
  const [titleCharCount, setTitleCharCount] = useState(0);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setItemData({ ...itemData, [name]: value });
    if (name === 'title') {
      setTitleCharCount(value.length);
    }

    if (name === 'zipCode' && value.replace('-', '').length === 8) {
      loadGoogleMapsScript(() => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ 'address': value + ', Brasil' }, function(results, status) {
          if (status === 'OK' && results[0]) {
            const locationDetails = results[0].address_components;
            setItemData(prevState => ({
              ...prevState,
              cidade: locationDetails.find(c => c.types.includes("administrative_area_level_2"))?.long_name,
              estado: locationDetails.find(c => c.types.includes("administrative_area_level_1"))?.short_name,
              bairro: locationDetails.find(c => c.types.includes("sublocality"))?.long_name,
              endereco: locationDetails.find(c => c.types.includes("route"))?.long_name
            }));
          } else {
            console.error('Geocode was not successful for the following reason: ' + status);
          }
        });
      });
    }
  };

  const handleRadioChange = (e) => {
    setItemData({ ...itemData, type: e.target.value });
  };

  const isFormValid = () => {
    return itemData.type && itemData.title && itemData.category && itemData.contactEmail && itemData.zipCode && itemData.description && itemData.estado && itemData.cidade && itemData.bairro && itemData.endereco && itemData.numero;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(itemData); // Add this to check what values are being captured.

    if (!isFormValid()) {
      alert('Por favor, preencha todos os campos.');
      return;
    }
    try {
      await addDoc(collection(db, 'mural'), {
        ...itemData,
        userId,
        createdAt: serverTimestamp(),
      });
      alert('Item publicado com sucesso!');
      onClose();
      navigate('/mural-da-rede');
    } catch (error) {
      console.error('Erro ao publicar item:', error);
      alert('Erro ao publicar item.');
    }
  };

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-[9998] bg-yellow-300 flex justify-center items-center overflow-auto">
      <div className="relative bg-white rounded-2xl p-10 flex flex-col w-11/12 md:w-2/5 mx-auto max-h-[90vh] overflow-y-auto overflow-x-hidden">
        <button
          className="absolute top-0 right-0 mt-4 mr-4 w-8 h-8 bg-transparent border-none cursor-pointer"
          onClick={onClose}
        >
          <img src={closeBtnImage} alt="Close" />
        </button>

        <H2 text="Publicar item" textColor="purple" />
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mb-4">
            <span className="font-normal text-base text-gray-600">O que deseja publicar?</span>
            <div className="flex flex-col md:flex-row gap-x-4 mt-6">
              <label className="font-normal text-base text-gray-600 flex items-center mb-2 md:mb-0">
                <input
                  type="radio"
                  name="type"
                  value="demand"
                  onChange={handleRadioChange}
                  checked={itemData.type === 'demand'}
                  className="mr-2"
                />
                Uma demanda
              </label>
              <label className="font-normal text-base text-gray-600 flex items-center">
                <input
                  type="radio"
                  name="type"
                  value="offer"
                  onChange={handleRadioChange}
                  checked={itemData.type === 'offer'}
                  className="mr-2"
                />
                Uma oferta
              </label>
            </div>
          </div>

          <div className="flex flex-wrap -mx-2 mb-4">
            <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
              <label className="font-normal text-base text-gray-600 block">
                Título
                <input
                                  type="text"
              name="title"
              value={itemData.title}
              onChange={handleInputChange}
              maxLength="40"
              required
              className="w-full border h-12 border-gray-300 rounded-xl p-2 mt-1"
            />
          </label>
          <p className="text-left text-gray-600 text-sm">Caracteres: {titleCharCount}/40</p>
        </div>
        <div className="w-full md:w-1/2 px-2">
          <label className="font-normal text-base text-gray-600 block">
            Categoria
            <select
              name="category"
              value={itemData.category}
              onChange={handleInputChange}
              required
              className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1"
            >
              <option value="">Selecione</option>
              <option value="moradia">Moradia</option>
              <option value="alimentacao">Alimentação</option>
              <option value="trocas">Trocas</option>
              <option value="transporte">Transporte</option>
              <option value="documentacao">Documentação</option>
              <option value="emprego">Emprego/Renda</option>
              <option value="comunicacao">Comunicação</option>
              <option value="treinamento">Treinamento</option>
              <option value="saude">Saúde</option>
              <option value="outros">Outros</option>
            </select>
          </label>
        </div>
      </div>

      <div className="flex flex-wrap -mx-2 mb-4">
        <div className="w-full lg:w-1/2 px-2 mb-4 lg:mb-0">
          <label className="font-normal text-base text-gray-600 block">
            Insira um e-mail de contato
            <input
              type="email"
              name="contactEmail"
              value={itemData.contactEmail}
              onChange={handleInputChange}
              required
              className="w-full border border-gray-300 rounded-xl h-12 p-2 mt-1"
            />
          </label>
        </div>
        <div className="w-full lg:w-1/2 px-2">
          <label className="font-normal text-base text-gray-600 block">
            CEP da oferta/demanda
            <InputMask
              mask="99999-999"
              value={itemData.zipCode}
              onChange={handleInputChange}
            >
              {(inputProps) => (
                <input
                  {...inputProps}
                  type="text"
                  name="zipCode"
                  required
                  className="w-full border border-gray-300 rounded-xl h-12 p-2 mt-1"
                />
              )}
            </InputMask>
          </label>
        </div>
      </div>

      <div className="flex flex-wrap -mx-2 mb-4">
        <div className="w-full lg:w-1/2 px-2 mb-4 mt-2 lg:mb-0">
            <label className="font-normal text-base text-gray-600 block">
              Estado
              <input
                type="text"
                name="estado"
                value={itemData.estado}
                onChange={handleInputChange}
                required
                className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1"
                placeholder="Estado"
              />
            </label>
            <label className="font-normal text-base text-gray-600 mt-2 block">
              Cidade
              <input
                type="text"
                name="cidade"
                value={itemData.cidade}
                onChange={handleInputChange}
                required
                className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1"
                placeholder="Cidade"
              />
            </label>
        </div>
        <div className="w-full lg:w-1/2 px-2 mb-4 mt-2 lg:mb-0">
            <label className="font-normal text-base text-gray-600 block">
              Bairro
              <input
                type="text"
                name="bairro"
                value={itemData.bairro}
                onChange={handleInputChange}
                required
                className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1"
                placeholder="Bairro"
              />
            </label>
            <label className="font-normal text-base mt-2 text-gray-600 block">
              Endereço
              <input
                type="text"
                name="endereco"
                value={itemData.endereco}
                onChange={handleInputChange}
                required
                className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1"
                placeholder="Endereço"
              />
            </label>
        </div>
        <div className="w-full lg:w-1/2 px-2 mb-4 mt-2 lg:mb-0">
            <label className="font-normal text-base text-gray-600 block">
              Número
              <input
                type="text"
                name="numero"
                value={itemData.numero}
                onChange={handleInputChange}
                required
                className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1"
                placeholder="Número"
              />
            </label>
        </div>
          </div>
          <div className="mb-4">
        <label className="font-normal text-base text-gray-600 block">
            Descreva brevemente a oferta/demanda
            <textarea
                name="description"
                value={itemData.description}
                onChange={handleInputChange}
                maxLength="450"
                required
                className="w-full h-36 p-2 border border-gray-300 rounded-md"
            />
        </label>
        <p className="text-left text-sm text-gray-600">Caracteres: {itemData.description.length}/450</p>
    </div>
          <div className="flex justify-end mt-4">
            <Button text="Publicar" variant="purple" type="submit" />
          </div>
        </form>
      </div>
    </div>,
    document.body
  );
};

export default PublishItemPopup;